import React, { FC, useEffect, useRef, useState } from 'react';
import useCloudcarScripts from 'hooks/useCloudcarScripts';
import styles from './Demo.module.scss';

interface DemoProps {
  name: string;
  html: string;
  carGroupId?: string;
  carId?: string;
  height: string;
  padding: string;
  left?: string;
  right?: string;
  componentType?: string;
}

const Demo: FC<DemoProps> = (props: DemoProps) => {
  const { html, name, carGroupId, carId, height, padding, left, right, componentType } =
    props;
  const iframeRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [visibility, setVisibility] = useState(false);
  useCloudcarScripts(name, divRef);

  useEffect(() => {
    if (iframeRef && iframeRef.current) {
      setIframeHeight(iframeRef.current.offsetHeight);
      if (
        (iframeRef.current.children[1] as HTMLIFrameElement).contentWindow &&
        (iframeRef.current.children[1] as HTMLIFrameElement).contentWindow?.document
      ) {
        const { body } = (iframeRef.current.children[1] as HTMLIFrameElement)
          .contentWindow?.document as Document;
        body?.style.setProperty('overflow', 'hidden');
      }
    }
  }, [iframeRef, visibility]);

  return (
    <div ref={iframeRef}>
      <div
        style={{
          position: 'absolute',
          padding,
          height: iframeHeight,
          width: '100%',
          visibility: visibility ? 'visible' : 'hidden',
          left,
          right,
        }}
        className="cloudcar_button_container"
        data-car-group-id={carGroupId}
        data-car-id={carId}
        data-concessionaire-name={name}
        data-component-type={componentType}
        ref={divRef}
      />
      <iframe
        id="myframe"
        title={name}
        srcDoc={html}
        className={styles.Iframe}
        style={{ height }}
        onLoad={() => setVisibility(true)}
      />
    </div>
  );
};

Demo.defaultProps = {
  carGroupId: undefined,
  carId: undefined,
  left: undefined,
  right: undefined,
  componentType: undefined,
};

export default Demo;
