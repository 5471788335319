import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const useAnalytics = (): boolean => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('G-JN8SQQCELP');
      setInitialized(true);
    }
  }, []);

  return initialized;
};

export default useAnalytics;
