/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Faq from 'views/Faq';
import AnalyticsWrapper from 'config/AnalyticsWrapper';
import useAnalytics from 'config/useAnalytics';
import Layout from 'components/ui/Layout';
import Home from 'views/Home';
import OurCars from 'views/OurCars';
import Concessionaire from 'views/Concessionaire';
import TermsAndConditions from 'views/TermsAndConditions';
import PrivacyPolicy from 'views/PrivacyPolicy';
import Test from 'views/Test';
import Demo from 'views/Demo';
import demos from 'demos';

const Routes = (): React.ReactElement => {
  const initialized = useAnalytics();
  return (
    <Router>
      <AnalyticsWrapper initialized={initialized}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Layout>
                <Home />
              </Layout>
            )}
          />
          <Route
            exact
            path="/test"
            render={() => (
              <Layout>
                <Test />
              </Layout>
            )}
          />
          <Route
            exact
            path="/our-cars"
            render={() => (
              <Layout>
                <OurCars />
              </Layout>
            )}
          />
          <Route
            exact
            path="/salazar"
            render={() => (
              <Layout>
                <Concessionaire />
              </Layout>
            )}
          />
          <Route
            exact
            path="/faq"
            render={() => (
              <Layout>
                <Faq />
              </Layout>
            )}
          />
          <Route
            exact
            path="/terms-and-conditions"
            render={() => (
              <Layout>
                <TermsAndConditions />
              </Layout>
            )}
          />
          <Route
            exact
            path="/privacy-policy"
            render={() => (
              <Layout>
                <PrivacyPolicy />
              </Layout>
            )}
          />
          {demos.map((demo) => (
            <Route
              exact
              path={demo.route}
              key={demo.route}
              render={() => <Demo {...demo} />}
            />
          ))}
          <Redirect exact path="*" to="/" />
        </Switch>
      </AnalyticsWrapper>
    </Router>
  );
};

export default Routes;
