import React, { FC, useState, useEffect, ReactElement } from 'react';
import Logo from 'assets/images/cloudcar-logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useMaxBreakpoint, Breakpoints } from '@cloudcar-app/cloudcar-components';
import DesktopTopBar from './DesktopTopBar';
import ResponsiveTopBar from './ResponsiveTopBar';
import { MenuPath } from './types';
import styles from './TopBar.module.scss';

const TopBar: FC = () => {
  const isWindowAvailable = typeof window !== 'undefined';
  const getPosition = (): number => (isWindowAvailable ? window.pageYOffset : 0);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const location = useLocation();
  const maxBreakpoint = useMaxBreakpoint();
  const showResponsiveTopBar = maxBreakpoint < Breakpoints.LG;
  const isCurrentViewHome = location.pathname === '/';

  const generateNavBarLinkClassNames = (linkLocation: string): string => {
    const clasNames = [styles.NavLink];
    if (linkLocation === location.pathname) {
      clasNames.push(styles.CurrentLocation);
    }
    return clasNames.join(' ');
  };

  const generateMenuLink = (menuPath: MenuPath): ReactElement => {
    return (
      <Menu.Item key={menuPath.name}>
        <Link to={menuPath.path} className={generateNavBarLinkClassNames(menuPath.path)}>
          <span>{menuPath.name}</span>
        </Link>
      </Menu.Item>
    );
  };

  const handleScroll = () => {
    if (getPosition() > 10) {
      setIsPageScrolled(true);
    } else {
      setIsPageScrolled(false);
    }
  };

  useEffect((): void => {
    if (isWindowAvailable) {
      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  const stickyClassNames = [
    styles.Sticky,
    !isPageScrolled ? styles.Transparent : ' ',
  ].join(' ');

  const containerClassNames = [
    styles.Container,
    !isPageScrolled ? styles.Transparent : ' ',
  ].join(' ');

  const demoButtonClassNames = [
    styles.Button,
    !isPageScrolled && isCurrentViewHome ? styles.Unscrolled : ' ',
  ].join(' ');

  const menuPaths: MenuPath[] = [
    {
      path: '/',
      name: 'Home',
    },
    {
      path: '/our-cars',
      name: 'Nuestros vehículos',
    },
  ];

  const cloudcarLogo = (
    <Link to="/">
      <img src={Logo} alt="CloudCar Logo" className={styles.Logo} />
    </Link>
  );
  if (showResponsiveTopBar) {
    return (
      <ResponsiveTopBar
        stickyClassNames={stickyClassNames}
        containerClassNames={containerClassNames}
        cloudcarLogo={cloudcarLogo}
        menuPaths={menuPaths}
        demoButtonClassNames={demoButtonClassNames}
        generateNavBarLinkClassNames={generateNavBarLinkClassNames}
        generateMenuLink={generateMenuLink}
      />
    );
  }
  return (
    <DesktopTopBar
      stickyClassNames={stickyClassNames}
      containerClassNames={containerClassNames}
      cloudcarLogo={cloudcarLogo}
      menuPaths={menuPaths}
      demoButtonClassNames={demoButtonClassNames}
      generateNavBarLinkClassNames={generateNavBarLinkClassNames}
      generateMenuLink={generateMenuLink}
    />
  );
};

export default TopBar;
