import React, { FC } from 'react';
import RichTextContainer from 'components/generalComponents/RichTextContainer';

const PrivacyPolicy: FC = () => {
  return (
    <>
      <RichTextContainer sectionToShow="PP" />
    </>
  );
};
export default PrivacyPolicy;
