/* eslint-disable import/no-webpack-loader-syntax */
import autoSummit from '!!raw-loader!./autoSummit.html';
import curifor from '!!raw-loader!./curifor.html';
import difor from '!!raw-loader!./difor.html';
import hernandezMotores from '!!raw-loader!./hernandezMotores.html';
import honda from '!!raw-loader!./honda.html';
import inalco from '!!raw-loader!./inalco.html';
import kia from '!!raw-loader!./kia.html';
import nissanChile from '!!raw-loader!./nissanChile.html';
import mercadoLibre from '!!raw-loader!./mercadoLibre.html';
import pompeyo from '!!raw-loader!./pompeyo.html';
import salazarIsrael from '!!raw-loader!./salazarIsrael.html';
import salfa from '!!raw-loader!./salfa.html';
import yapo from '!!raw-loader!./yapo.html';

interface DemoInfo {
  name: string;
  html: string;
  route: string;
  carGroupId?: string;
  height: string;
  padding: string;
  left?: string;
  right?: string;
  componentType?: string;
  carId?: string;
}

const demos: DemoInfo[] = [
  {
    name: 'AutoSummit',
    html: autoSummit as string,
    route: '/auto-summit',
    carGroupId: '8086cf630616729de2a741ed8631c9d7a8f20d62',
    height: '3100px',
    padding: '1900px 100px 0',
  },
  {
    name: 'Curifor',
    html: curifor as string,
    route: '/curifor',
    carGroupId: 'e6a28c3df2caabb6a2423cb1340d081bbac5fc93',
    height: '4450px',
    padding: '3600px 100px 0',
  },
  {
    name: 'Difor',
    html: difor as string,
    route: '/difor',
    carGroupId: 'c4f3969889c439cd279843626108ebeea89c6a12',
    height: '2500px',
    padding: '250px 100px 0',
  },
  {
    name: 'HMotores',
    html: hernandezMotores as string,
    route: '/hernandez-motores',
    carGroupId: '496671c2654388a8829c2727fe280ea364ed7129',
    height: '5900px',
    padding: '3850px 100px 0',
  },
  {
    name: 'Honda',
    html: honda as string,
    route: '/honda',
    carGroupId: 'e287fcfe7b4ae55a7b13d1d7cdba64159c3397b9',
    height: '10000px',
    padding: '9000px 100px 0',
  },
  {
    name: 'Inalco',
    html: inalco as string,
    route: '/inalco',
    carGroupId: 'f0504d3b967d325a491f0e899a4d19dad425566a',
    height: '7000px',
    padding: '350px 100px 0',
  },
  {
    name: 'Kia',
    html: kia as string,
    route: '/kia',
    carGroupId: '86921622867dc76108aac0376f2bd4cb1323ff32',
    height: '8700px',
    padding: '2250px 100px 0',
  },
  {
    name: 'NissanChile',
    html: nissanChile as string,
    route: '/nissan-chile',
    carGroupId: 'd87c4f42980d9f84aae2afe6bb833d30eda5791b',
    height: '99vh',
    padding: '10vh 100px 0',
  },
  {
    name: 'MercadoLibre',
    html: mercadoLibre as string,
    route: '/mercado-libre',
    carGroupId: '05a11f2e6c96aae8bdcbab3308a1120eab6c8b41',
    height: '2000px',
    padding: '470px 0',
    right: '0',
    left: 'calc(100vw - 570px)',
    componentType: 'BUTTON',
  },
  {
    name: 'PompeyoCarrasco',
    html: pompeyo as string,
    route: '/pompeyo-carrasco',
    carGroupId: 'c4f3969889c439cd279843626108ebeea89c6a12',
    height: '3500px',
    padding: '180px 180px 0',
  },
  {
    name: 'SalazarIsrael',
    html: salazarIsrael as string,
    route: '/salazar-israel',
    carGroupId: '727c555dccaa0d65918093da9e138af8cf1a2160',
    height: '2400px',
    padding: '1650px 250px 0',
  },
  {
    name: 'Salfa',
    html: salfa as string,
    route: '/salfa',
    carGroupId: 'bdbc6e229cf6001bd90eb7f7a7defe1791819678',
    height: '4900px',
    padding: '780px 180px 0',
  },
  {
    name: 'Yapo',
    html: yapo as string,
    route: '/yapo',
    carGroupId: '92f18fbb404f1d146d8fa37c4adc0c34b700b93a',
    height: '2000px',
    padding: '455px 0',
    right: '0',
    left: 'calc(100vw - 50% - 150px)',
    componentType: 'BUTTON',
  },
];

export default demos;
