import { cloudcarApiAxiosInstance } from './utils';

export enum AllowedComponent {
  Button = 'BUTTON',
  Card = 'CARD',
  Widget = 'WIDGET',
}

export type ConcessionaireStyle = {
  __typename?: 'ConcessionaireStyle';
  buttonTextColor?: string;
  componentType: AllowedComponent;
  concessionaireId: string;
  concessionaireIsoLogoUrl?: string;
  concessionaireLogoUrl?: string;
  createdAt: string;
  primaryColor?: string;
  showRemainingQuantity: boolean;
  textColor?: string;
};

interface ConfigurationResponse {
  response: ConcessionaireStyle;
  status: number;
}

const getConcessionaireStyle = async ({
  concessionaireName,
}: {
  concessionaireName: string;
}): Promise<ConfigurationResponse> => {
  const response = await cloudcarApiAxiosInstance({
    method: 'GET',
    url: `/concessionaireStyle/${concessionaireName}`,
    validateStatus: (status) => status === 200,
  });
  return {
    response: response.data as ConcessionaireStyle,
    status: response.status,
  };
};

const services = {
  getConcessionaireStyle,
};

export default services;
