import React, { FC, useState } from 'react';
import { Row, Col, Drawer, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import Container from '../../Container';
import { TopBarProps } from '../types';
import styles from './responsiveTopBar.module.scss';

const ResponsiveTopBar: FC<TopBarProps> = (props: TopBarProps) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const {
    stickyClassNames,
    containerClassNames,
    cloudcarLogo,
    menuPaths,
    generateNavBarLinkClassNames,
    generateMenuLink,
  } = props;
  const { SubMenu } = Menu;

  const onCloseDrawer = (): void => {
    setShowDrawer(false);
  };

  const foldSideMenu = (): void => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <div className={stickyClassNames}>
        <Container>
          <div className={containerClassNames}>
            <Row justify="space-between">
              <Col xs={6} sm={4}>
                <Row justify="start">
                  <MenuOutlined className={styles.UnfoldIcon} onClick={foldSideMenu} />
                </Row>
              </Col>
              <Col xs={12} sm={16}>
                <Row justify="center">
                  <Col>{cloudcarLogo}</Col>
                </Row>
              </Col>
              <Col xs={6} sm={4} />
            </Row>
          </div>
        </Container>
      </div>
      <Drawer
        title={cloudcarLogo}
        placement="left"
        closable={false}
        onClose={onCloseDrawer}
        visible={showDrawer}
      >
        <Menu mode="inline" style={{ width: '100%', border: '0' }}>
          {menuPaths.map((menuPath) => {
            if (menuPath.subPaths) {
              return (
                <SubMenu
                  key={menuPath.name}
                  title={
                    <Link
                      to={menuPath.path}
                      className={generateNavBarLinkClassNames(menuPath.path)}
                    >
                      <span>{menuPath.name}</span>
                    </Link>
                  }
                >
                  {menuPath.subPaths.map((subPath) => {
                    return generateMenuLink(subPath);
                  })}
                </SubMenu>
              );
            }
            return generateMenuLink(menuPath);
          })}
        </Menu>
      </Drawer>
    </>
  );
};

export default ResponsiveTopBar;
