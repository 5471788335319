import React, { FC } from 'react';
import { Row, Col } from 'antd';
import useCloudcarScripts from 'hooks/useCloudcarScripts';
import Container from 'components/ui/Container';
import styles from './carWidget.module.scss';

const CarWidget: FC = () => {
  useCloudcarScripts('Ditec');
  return (
    <div className={styles.Background}>
      <Container>
        <Row justify="center">
          <h1 className={styles.Title}>Configura tu Volvo XC90 Recharge</h1>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              className="cloudcar_button_container"
              data-car-group-id="1d9146cd7f6bf78b1c06db01ffe837398ee4c246"
              data-concessionaire-name="Ditec"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CarWidget;
