import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Button } from 'antd';
import styles from './car.module.scss';

interface CarProps {
  image: string;
  title: string;
  content: string;
  button: string;
  link: string;
}

const Car: FC<CarProps> = (props: CarProps) => {
  const { image, title, content, button, link } = props;
  const history = useHistory();
  return (
    <div className={styles.Car}>
      <Row justify="start">
        <img src={image} alt="car" width="270px" />
      </Row>
      <Row justify="start">
        <h5 className={styles.Title}>{title}</h5>
      </Row>
      <Row justify="start">
        <p className={styles.Description}>{content}</p>
      </Row>
      <Row justify="start">
        <Button
          className={styles.Button}
          type="primary"
          onClick={() => history.push(link)}
        >
          {button}
        </Button>
      </Row>
    </div>
  );
};

export default Car;
