import React, { FC } from 'react';
import TopBar from 'components/ui/TopBar';
import Footer from 'components/ui/Footer';
import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = (props: LayoutProps) => {
  const { children } = props;

  return (
    <div className={styles.MainBackgroud}>
      <TopBar />
      <div className={styles.Content}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
