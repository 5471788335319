import { axiosInstance } from './utils';
import { AxiosInstanceResponse } from './types';

const getLegalDocument = async (): Promise<AxiosInstanceResponse> => {
  const response = await axiosInstance({
    method: 'get',
    url: 'https://cloudcar-api-terms-and-conditions-template-dev.s3.us-east-1.amazonaws.com/TERMINOSYCONDICIONES.html',
    validateStatus: (status) => status >= 200 && status < 400,
  });
  return { response: response.data as string, status: response.status };
};

const getTermsAndConditions = async (): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { response } = await getLegalDocument();
  return (response as string).split('<hr>')[0];
};

const getPrivacyTerms = async (): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { response } = await getLegalDocument();
  return (response as string).split('<hr>')[1];
};

const emailService = {
  getTermsAndConditions,
  getPrivacyTerms,
};

export default emailService;
