import React, { FC } from 'react';
import { Row, Col } from 'antd';
import useCloudcarScripts from 'hooks/useCloudcarScripts';
import Container from 'components/ui/Container';
import styles from './carsWidgets.module.scss';

const CarsWidgets: FC = () => {
  useCloudcarScripts('CloudCar');
  return (
    <div className={styles.Background}>
      <Container>
        <Row justify="start" id="XC90">
          <h1 className={styles.Title}>Concesionario CloudCar</h1>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              className="cloudcar_button_container"
              data-car-group-id="c4f3969889c439cd279843626108ebeea89c6a12"
              data-concessionaire-name="CloudCar"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CarsWidgets;
