import React, { FC } from 'react';
import { Row, Col, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import Container from '../../Container';
import { TopBarProps } from '../types';
import styles from './desktopTopBar.module.scss';

const DesktopTopBar: FC<TopBarProps> = (props: TopBarProps) => {
  const {
    stickyClassNames,
    containerClassNames,
    cloudcarLogo,
    menuPaths,
    generateNavBarLinkClassNames,
    generateMenuLink,
  } = props;

  return (
    <div className={stickyClassNames}>
      <Container>
        <div className={containerClassNames}>
          <Row justify="start">
            <Col xs={9}>
              <Row justify="start">
                <Col>{cloudcarLogo}</Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row justify="space-between">
                {menuPaths.map((menuPath) => {
                  if (menuPath.subPaths) {
                    const menuItems = menuPath.subPaths.map((subPath) => {
                      return generateMenuLink(subPath);
                    });
                    const menu = <Menu>{menuItems}</Menu>;

                    return (
                      <Dropdown
                        overlay={menu}
                        key={menuPath.name}
                        overlayClassName={styles.Dropdown}
                      >
                        <Link
                          to={menuPath.path}
                          className={generateNavBarLinkClassNames(menuPath.path)}
                        >
                          <span className={styles.DynamicColor}>
                            {menuPath.name} <DownOutlined />
                          </span>
                        </Link>
                      </Dropdown>
                    );
                  }
                  return (
                    <Col key={menuPath.name}>
                      <Link
                        to={menuPath.path}
                        className={generateNavBarLinkClassNames(menuPath.path)}
                      >
                        <span className={styles.DynamicColor}>{menuPath.name}</span>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DesktopTopBar;
