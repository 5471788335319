const hex2rgb = (hex: string): number[] => {
  let hexString = hex;
  const result = [];
  if (hex.charAt(0) === '#') {
    hexString = hexString.substr(1);
  }
  if (hexString.length === 3) {
    hexString =
      hexString[0] +
      hexString[0] +
      hexString[1] +
      hexString[1] +
      hexString[2] +
      hexString[2];
  }
  for (let i = 0; i < 3; i += 1) {
    result[i] = parseInt(hexString.substr(i * 2, 2), 16);
  }
  return result;
};

export default hex2rgb;
