/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import legalDocumentsService from 'services/legalDocumentsService';
import Container from 'components/ui/Container';
import styles from './richTextContainer.module.scss';

interface RichTextContainerProps {
  sectionToShow: string;
}

const RichTextContainer: FC<RichTextContainerProps> = (props: RichTextContainerProps) => {
  const { sectionToShow } = props;
  const [legalDocumentText, setLegalDocumentText] = useState(' ');

  useEffect(() => {
    const getDocuments = async () => {
      if (sectionToShow === 'T&C') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response = await legalDocumentsService.getTermsAndConditions();
        setLegalDocumentText(response);
      } else if (sectionToShow === 'PP') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response = await legalDocumentsService.getPrivacyTerms();
        setLegalDocumentText(response);
      }
    };
    getDocuments();
  }, []);

  return (
    <div className={styles.ContainerRow}>
      <Container>{parse(legalDocumentText)}</Container>
    </div>
  );
};

export default RichTextContainer;
