import React, { FC } from 'react';
import FrequentlyAskedQuestions from 'components/faqComponents/FrequentlyAskedQuestions';

const Faq: FC = () => {
  return (
    <>
      <FrequentlyAskedQuestions />
    </>
  );
};

export default Faq;
