import React, { FC, useState } from 'react';
import Container from 'components/ui/Container';
import { Col, Row, Collapse, Divider } from 'antd';
import _ from 'lodash';
import styles from './frequentlyAskedQuestions.module.scss';
import { Questions } from './types';

const { Panel } = Collapse;
const FrequentlyAskedQuestions: FC = () => {
  const [activeKey, setActiveKey] = useState(['1']);

  const handleChangeActivePanel = (key: string | string[]): void => {
    if (_.isArray(key)) {
      setActiveKey(key);
    } else if (typeof key === 'string') {
      setActiveKey([key]);
    }
  };

  const specs: Questions[] = [
    {
      key: '1',
      header: '¿Qué es CloudCar?',
      answer: (
        <>
          <p className={styles.Answer}>
            <strong>CloudCar</strong> es una plataforma que permite a las concesionarias
            ofrecer a sus clientes un nuevo canal de ventas 100% online y disponible 24/7.
          </p>
          <p className={styles.Answer}>
            Esto consiste en un <strong>Botón de pago</strong>, que se integra
            directamente a la página del concesionario, adaptándose personalizadamente.
          </p>
        </>
      ),
    },
    {
      key: '2',
      header: '¿Qué incluye este canal de ventas 100% online?',
      answer: (
        <>
          <p className={styles.Answer}>
            Este nuevo canal de ventas es un paso a paso, a través del cual tus clientes
            podrán comprar un vehículo 100% online desde tu propia página web con los
            mismos beneficios que se entregan en el piso de ventas.
          </p>
          <p className={styles.Answer}>Este incluye:</p>
          <ul>
            <li className={styles.AnswerPoints}>
              <strong>Botón</strong> “comprar” personalizado en la página del
              concesionario.
            </li>
            <li className={styles.AnswerPoints}>
              Elegir{' '}
              <span
                role="link"
                tabIndex={0}
                onClick={() => handleChangeActivePanel('3')}
                onKeyPress={() => handleChangeActivePanel('3')}
                className={styles.Link}
              >
                tipo de compra:
              </span>{' '}
              Al contado o con financiamiento.
            </li>
            <li className={styles.AnswerPoints}>Despacho o retiro en sucursal.</li>
            <li className={styles.AnswerPoints}>
              <span
                role="link"
                tabIndex={0}
                onClick={() => handleChangeActivePanel('5')}
                onKeyPress={() => handleChangeActivePanel('5')}
                className={styles.Link}
              >
                Tramitación 100% en línea.
              </span>
            </li>
            <li className={styles.AnswerPoints}>Cotización y contratación de seguro.</li>
            <li className={styles.AnswerPoints}>Pago a través Webpay.</li>
          </ul>
        </>
      ),
    },
    {
      key: '3',
      header: '¿Qué tipo de compra puedo ofrecer a mis clientes?',
      answer: (
        <>
          <p className={styles.Answer}>Puedes ofrecer 2 tipos de compras:</p>
          <ul>
            <li className={styles.AnswerPoints}>Al contado</li>
            <li className={styles.AnswerPoints}>
              <span
                role="link"
                tabIndex={0}
                onClick={() => handleChangeActivePanel('4')}
                onKeyPress={() => handleChangeActivePanel('4')}
                className={styles.Link}
              >
                Con financiamiento,
              </span>{' '}
              tanto crédito convencional y crédito inteligente.
            </li>
          </ul>
        </>
      ),
    },
    {
      key: '4',
      header: '¿En qué consiste la compra con financiamiento?',
      answer: (
        <>
          <p className={styles.Answer}>
            Actualmente estamos integrados a FORUM ofreciendo todos sus productos. Estamos
            constantemente integrándonos con otras financieras para ofrecer múltiples
            opciones a tus clientes.
          </p>
          <p className={styles.Answer}>
            Siempre respetaremos cualquier acuerdo de exclusividad que puedes tener con
            alguna institución financiera.
          </p>
        </>
      ),
    },
    {
      key: '5',
      header: '¿Qué trámites puedo ofrecer a mis clientes?',
      answer: (
        <>
          <p className={styles.Answer}>
            Puedes ofrecerles 6 tipos de trámites. Pago del impuesto verde, inscripción en
            el registro civil, SOAP, permiso de circulación, inscripción del TAG y
            prohibición de enajenar en caso de que la venta sea con financiamiento.
          </p>
        </>
      ),
    },
    {
      key: '6',
      header: '¿Cómo me puedo integrar a CloudCar?',
      answer: (
        <>
          <p className={styles.Answer}>
            Agregamos un <strong>Botón</strong> en tu página web, a través de este{' '}
            <strong>Botón</strong> tus clientes podrán acceder a un flujo de compra, en el
            cual tendrán que ingresar su datos personales, podrán cotizar un crédito,
            elegir trámites y pagar el pié (o el total) del vehículo. Este flujo de compra
            100% digital tiene una disponibilidad 24/7.
          </p>
          <p className={styles.Answer}>
            A nuestros clientes también les entregamos acceso a un <em>backoffice</em>, en
            el cual es posible administrar los vehículos en venta, configurar el estilo
            del <strong>Botón</strong> y flujo de compra, visualizar y administrar tus
            ventas, entre otros. Si es que utilizas un DMS, nuestro <em>backoffice</em> se
            puede integrar a este para sincronizar tu stock, información de los vehículos,
            precios, etc.. Si es que no utilizas un DMS, nuestro <em>backoffice</em> te
            entrega todas las herramientas para administrar tu stock, precios,
            información, ofertas, etc.
          </p>
          <p className={styles.Answer}>
            A través de nuestro flujo de compra tus clientes podrán acceder a productos
            financieros y seguros. Tú puedes elegir con qué compañías deseas trabajar,
            siempre respetaremos cualquier acuerdo comercial que puedas tener.
          </p>
          <p className={styles.Answer}>
            Finalmente, tanto el <strong>Botón</strong> como el flujo de compra se adaptan
            al estilo gráfico de tu marca, para una experiencia de compra consistente y
            confiable.
          </p>
        </>
      ),
    },
  ];

  return (
    <div>
      <Container>
        <div className={styles.ContainerRow}>
          <Row justify="center" className={styles.TitleRow}>
            <h2>Preguntas frecuentes</h2>
          </Row>

          <Row justify="center">
            <Col xs={24} sm={16}>
              <Collapse
                bordered={false}
                activeKey={activeKey}
                onChange={handleChangeActivePanel}
                ghost
              >
                {specs.map((spec: Questions) => (
                  <>
                    <Panel
                      className={styles.Question}
                      header={spec.header}
                      key={spec.key}
                    >
                      <p>{spec.answer}</p>
                    </Panel>
                    <Divider className={styles.Margin} />
                  </>
                ))}
              </Collapse>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default FrequentlyAskedQuestions;
