import React, { FC } from 'react';
import { Row, Col } from 'antd';
import Car from 'components/generalComponents/Car';
import Container from 'components/ui/Container';
import XC40T5 from 'assets/images/xc40-t5.jpg';
import XC40P8 from 'assets/images/xc40-p8.jpg';
import S60T8 from 'assets/images/s60-t8.jpg';
import XC60T8 from 'assets/images/xc60-t8.jpg';
import styles from './otherVehicles.module.scss';

const OtherVehicles: FC = () => {
  return (
    <div className={styles.Background}>
      <Container>
        <Row justify="center">
          <h1 className={styles.Title}>Otros modelos que te pueden interesar</h1>
        </Row>
        <Row justify="space-between" gutter={[24, 24]} className={styles.Content}>
          <Col xs={24} sm={12} md={6}>
            <Car
              image={XC60T8}
              title="Volvo XC60 Recharge"
              content="Híbrido"
              button="Ver modelo"
              link="/our-cars#XC60"
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Car
              image={XC40T5}
              title="Volvo XC40 Recharge"
              content="Híbrido"
              button="Ver modelo"
              link="/our-cars"
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Car
              image={XC40P8}
              title="Volvo XC40 Recharge"
              content="Eléctrico"
              button="Ver modelo"
              link="/our-cars"
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Car
              image={S60T8}
              title="Volvo S60 Recharge"
              content="Híbrido"
              button="Ver modelo"
              link="/our-cars"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtherVehicles;
