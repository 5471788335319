import { MutableRefObject, useEffect, useState } from 'react';
import services from 'services';
import hex2rgb from 'utils/hex2rgb';
import config from 'config';

const { domain } = config;

const useCloudcarScripts = (
  concessionaireName: string,
  ref?: MutableRefObject<HTMLDivElement | null>
): void => {
  const { getConcessionaireStyle } = services;
  const [primaryColor, setPrimaryColor] = useState<string | null>(null);

  useEffect(() => {
    if (ref && ref.current && ref.current.children && primaryColor) {
      const cloudcarContainer = ref.current.children[0] as HTMLElement;
      const rgbprimary = hex2rgb(primaryColor);
      cloudcarContainer?.style.setProperty(
        '--primary',
        `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 1)`
      );
      cloudcarContainer?.style.setProperty(
        '--pale-primary',
        `rgba(${rgbprimary[0]}, ${rgbprimary[1]}, ${rgbprimary[2]}, 0.07)`
      );
      cloudcarContainer?.style.setProperty(
        '--dark-primary',
        `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ${primaryColor}`
      );
    }
  }, [primaryColor, ref]);

  useEffect(() => {
    getConcessionaireStyle({
      concessionaireName,
    }).then((response) => {
      if (response.response && response.response.primaryColor) {
        setPrimaryColor(response.response.primaryColor);
      }
    });
  }, []);

  useEffect(() => {
    const cloudcarScript = document.createElement('script');
    cloudcarScript.src = `https://button.cloudcar.${domain}/cloudcar-min.js`;
    cloudcarScript.async = true;

    document.body.appendChild(cloudcarScript);

    return () => {
      document.body.removeChild(cloudcarScript);
    };
  }, []);
};

export default useCloudcarScripts;
