import React, { FC } from 'react';
import Banner from 'components/homeComponents/Banner';
import OtherVehicles from 'components/homeComponents/OtherVehicles';
import CarWidget from 'components/homeComponents/CarWidget';

const Home: FC = () => {
  return (
    <>
      <Banner />
      <CarWidget />
      <OtherVehicles />
    </>
  );
};

export default Home;
