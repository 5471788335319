import { PropsWithChildren, useEffect } from 'react';
import ReactGa from 'react-ga4';
import { useLocation } from 'react-router-dom';

interface WrapperProps {
  initialized: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: PropsWithChildren<any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnalyticsWrapper = (props: WrapperProps): any => {
  const location = useLocation();

  useEffect(() => {
    if (props.initialized) {
      ReactGa.send('pageview');
    }
  }, [props.initialized, location]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return props.children;
};

export default AnalyticsWrapper;
