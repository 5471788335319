import React, { FC } from 'react';
import { useMaxBreakpoint, Breakpoints } from '@cloudcar-app/cloudcar-components';
import styles from './container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
}

const Container: FC<ContainerProps> = (props: ContainerProps) => {
  const { children } = props;
  const maxBreakpoint = useMaxBreakpoint();
  const classNames = [
    styles.Container,
    maxBreakpoint > (Breakpoints.LG as number) ? styles.addedPadding : '',
  ].join(' ');
  return <div className={classNames}>{children}</div>;
};

export default Container;
