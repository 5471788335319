import React, { FC } from 'react';
import { Row, Col } from 'antd';
import CloudcarLogo from 'assets/images/cloudcar-logo-b&w.svg';
import { Link } from 'react-router-dom';
import Container from '../Container';
import styles from './footer.module.scss';

const Footer: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <Container>
        <div className={styles.Container}>
          <Row justify="start" gutter={[16, 48]}>
            <Col xs={0} sm={12}>
              <Link to="/">
                <img src={CloudcarLogo} alt="logo" className={styles.Logo} />
              </Link>
            </Col>
            <Col xs={12} sm={6}>
              <Row justify="start">
                <Link to="/">
                  <h6>Menú</h6>
                </Link>
              </Row>
              <Row justify="start">
                <Link to="/" className={styles.FooterLink}>
                  Home
                </Link>
              </Row>
              <Row justify="start">
                <Link to="/our-cars" className={styles.FooterLink}>
                  Nuestros vehículos
                </Link>
              </Row>
            </Col>
            <Col xs={12} sm={6}>
              <Row justify="start">
                <h6>Más Información</h6>
              </Row>
              <Row justify="start">
                <Link to="/faq" className={styles.FooterLink}>
                  Preguntas frecuentes
                </Link>
              </Row>
              <Row justify="start">
                <Link to="/terms-and-conditions" className={styles.FooterLink}>
                  Términos y condiciones
                </Link>
              </Row>
              <Row justify="start">
                <Link to="/privacy-policy" className={styles.FooterLink}>
                  Política de privacidad
                </Link>
              </Row>
            </Col>
          </Row>
          <Row justify="center" className={styles.MarginTop}>
            <p className={styles.Licence}>
              <Link to="/" className={styles.FooterLink}>
                CloudCar
              </Link>{' '}
              {new Date().getFullYear()} | Todos los derechos reservados.
            </p>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
