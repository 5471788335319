import React, { FC } from 'react';
import CarsWidgets from 'components/ourCarsComponents/CarsWidgets';

const OurCars: FC = () => {
  return (
    <>
      <CarsWidgets />
    </>
  );
};

export default OurCars;
