import React, { FC } from 'react';
import { Row, Col } from 'antd';
import useCloudcarScripts from 'hooks/useCloudcarScripts';
import Container from 'components/ui/Container';

const Concessionaire: FC = () => {
  useCloudcarScripts('SalazarIsrael');

  return (
    <div>
      <Container>
        <Row justify="start" id="XC90">
          <h1>Salazar Israel</h1>
        </Row>
        <Row>
          <Col xs={24}>
            <div
              className="cloudcar_button_container"
              data-car-group-id="1c3cbd1285e544bf5e31f727aa9dcbe84818f8b3"
              data-concessionaire-name="Nissan"
              data-default-version="SENSE MT 2WD"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Concessionaire;
