import React, { FC } from 'react';
import { Carousel } from 'antd';
import BannerImg from 'assets/images/banner-ejemplo.png';
import styles from './banner.module.scss';

const Banner: FC = () => {
  return (
    <div className={styles.HomeBanner}>
      <Carousel autoplay>
        <div className={styles.CarouselStyle}>
          <img src={BannerImg} alt="ejemplo" className={styles.BackgroundImg} />
        </div>
        <div className={styles.CarouselStyle}>
          <img src={BannerImg} alt="ejemplo" className={styles.BackgroundImg} />
        </div>
      </Carousel>
    </div>
  );
};

export default Banner;
