import React, { FC } from 'react';
import RichTextContainer from 'components/generalComponents/RichTextContainer';

const TermsAndConditions: FC = () => {
  return (
    <>
      <RichTextContainer sectionToShow="T&C" />
    </>
  );
};

export default TermsAndConditions;
