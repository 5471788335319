import axios from 'axios';
import config from 'config';

const { apiUrl } = config;

// const mailingApiUrl = process.env.MAILING_API_URL as string;

export const axiosInstance = axios.create({
  baseURL: 'https://landing-mail.cloudcar.cl',
});

export const headerWithAuthorization = (): { [key: string]: string } => {
  return {
    authorization: `Bearer ${localStorage.getItem('AccessToken') as string}`,
  };
};

const cloudcarApiRestUrl = apiUrl;
export const cloudcarApiAxiosInstance = axios.create({
  baseURL: cloudcarApiRestUrl,
});
