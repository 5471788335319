import React, { FC } from 'react';
import { Col, Row } from 'antd';
import Banner from 'components/homeComponents/Banner';
import OtherVehicles from 'components/homeComponents/OtherVehicles';
import useCloudcarScripts from 'hooks/useCloudcarScripts';

const Home: FC = () => {
  useCloudcarScripts('TestNuevos');
  return (
    <>
      <Banner />
      <Row>
        <Col xs={24}>
          <div
            className="cloudcar_button_container"
            data-car-group-id="75d0fc58aee129ca121615d6a3525fb0eebb777b"
            data-concessionaire-name="TestNuevos"
          />
        </Col>
      </Row>
      <OtherVehicles />
    </>
  );
};

export default Home;
